


  
.highlight-animation 
{
    animation: highlight 0.2s linear; /* Adjust duration as needed */
}


  @keyframes highlight {
    0% { opacity: 0%; color: white;}
    100% { opacity: 100%; color: black;}
  }