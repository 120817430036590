.desc-text
{
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.736);
    border: 2px solid rgba(0, 0, 0, 0.448);
}

.dropdown-button
{   
    background-color: rgba(125, 124, 124, 0.276);
    color: black;

}

.buy-button
{
    background-color: green;


}


.fonter-text
{
    font-family: open sans,sans-serif;
}


.holder
{
    position: relative;
    height: 10px;
    width: 100%;
    background-color: white;


}

.tilter
{
    z-index: 1;
    background-color: #e2e2e2;
    transform: rotateZ(-5deg);
    position: absolute;
    width: 200%;
    height: 250px;
    top: -110px;
    left: -5%;
    border: 1px solid rgb(163, 155, 155);
}


.image-gallery {
    width: 100%;
    
}
  
  .image-gallery-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
  
  .color-options {
    display: flex;
    justify-content: start;
  }
  
  .image-gallery-thumbnail {
    width: 135px !important;
    /* height: 125px */
    border-radius: 10px;
  }
  
  

  .added-to-cart-not-pressed
  {
    display: none;
  }

  .added-to-cart
  {
    position: absolute;
    top: 60px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px !important;
    animation: blinker 0.1s linear;
    /* opacity: 1; */
}

  @keyframes blinker {
    from{
        height: 0px;
        opacity: 0;

    }to{
        opacity: 1;
        height: 40px;
    }
    
    
    /* 50% {
        opacity: 0;
      } */
  }

