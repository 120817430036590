
.admin-header-text {
    animation: slide-text 3s;
}

@keyframes slide-text {
    from {
        transform: translateX(-1000px);
    }
    to {
        transform: translateX(0px);
    }
}