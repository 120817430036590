


.zoom
{
    transform: scale(1.2);


}

.notianim{
    
    animation: dropDown 0.3s  ease-out;

}


@keyframes dropDown {
    from{
        transform: scale(0);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }

    
}


.notianim-dismiss {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }