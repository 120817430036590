


.header-div
{
    
    background-color: #e2e2e2;
    transform: rotateZ(-6deg);
    overflow: hidden;
    height: 97%;
    
    position: absolute;
    width: 120%;
    left: -10%;
    top: -35%;
    


}

.holder
{

    width: auto;
    height: 425px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;


}

.header-text
{
    width: 100%;
    height: 20%;
    z-index: 10;


}


.order-number-holder
{
    background-color: #ffd336;
    
    /* width: 150px;
    height: 35px; */
    border-radius: 10px;

}


.fade-in
{
    animation: fade 0.5s linear;
    opacity: 1;

}


@keyframes fade {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
    
}