.navBarColor
{
    /* background-color:#9d9d9d; */
    background-color: rgb(226, 226, 226);
    background-size: 0% 100%;
    transition: background-color 0.5s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.312);
    height: 100px;
}

.navBarColor:hover 
{
    /* What color we slide in to */
    /* background-color:#9e0606; */
     
    background-image:linear-gradient(120deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(159, 54, 54, 0.7) 100%);
    /* background-repeat:no-repeat; */
    background-size: 200% 100%; 
    /* transition:background-size 2.5s, background-color 2.5s; */
}

.navbar-text
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    scale: 1.2;
}

.navbar-text:hover
{
    scale: 1.3;

}




.logo-navbar
{
  width: 150px;
  height: auto;
}

.test
{
    background-color: aqua;
}


.boxContainer
{
    background-color: orange;
    border: 1px solid black;
    border-radius: 4px;
    
}

.slide-text-one
{
    /* font-family: Copperplate, Papyrus, fantasy;;  */
    

    
    animation: slide-left-top 2s;
   transform: translateX(40px);
   /* transform: translateX(35%); */
   color: rgba(255, 255, 255, 0.446);
}

.slide-text-two
{
    /* font-family: Copperplate, Papyrus, fantasy; */
    animation: slide-left-buttom 2s;
    transform: translateX(80px);
    color: rgba(255, 255, 255, 0.446);
}

.slide-text-background
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 100px;
    top: 5px;
    left: 0px;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.764);
    width: 20%;
    height: 90%;
    animation: slide-background 1.5s forwards;
}

.slide-text-background::before 
{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    clip-path: polygon(10% 0, 10% 0, 100% 0%, 100% 100%, 0 0%); 
    background-color: rgb(226, 226, 226);
    animation: slide-background-two 3.8s ease-out; 
}


.cartholder
{
    
    position: relative;
    /* background-color: black; */
    padding-left: 14px;
    
}

.cartnotify
{
    background-color: rgba(255, 0, 0, 0.623);
    border-radius: 60%;
    position: absolute;
    width: 22px;
    height: 22px;
    animation: bubblePop 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
    left: 0;
    top: 0;
}



.cartnotifyvalue
{
    user-select: none;
}

@keyframes bubblePop {
    from{transform: scale(0.0);}
    to{transform: scale(1.0);}
    
}





@keyframes slide-left-top {
    from{
        transform: translateX(-150px);
        opacity: 0;
    }
    to{
        transform: translateX(40px);
        opacity: 1;
    }
}





@keyframes slide-left-buttom {
    from{
        transform: translateX(-150px);
        opacity: 0;
    }
    to{
        transform: translateX(80px);
        opacity: 1;
    }
}

@keyframes slide-background 
{
    from{
        left: -200px;
        opacity: 0;
    }
    to{
        opacity: 1;
        left: 0px;

    }
    
}

@keyframes slide-background-two 
{
    from{
        
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}

@media (max-width: 1214px) {
    .div-to-hide {
        display: none;
    }
}



@media (max-width: 767px) { 

  #menu {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}
