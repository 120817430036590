.c-item
{
    height: 250px; 
    width: 100%;    
    overflow: hidden; 
    border-radius: 10px;
}

.c-img 
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 32%;
    border-radius: 10px;
}



.c-shadow
{   
    
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.74);
    border-radius: 10px;

}

.main-carou
{
    animation: fall-down 1s ;
}

@keyframes fall-down {
    from{
        transform: rotateX(90deg);
    }
    to{
        transform: rotateX(0deg);
    }
}