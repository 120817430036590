.large-monitor-disp {
    display: flex;
}

.small-monitor-disp {
    display: none !important;
}

@media (max-width: 767px) {
    .large-monitor-disp {
        display: none !important;
    }
    
    .small-monitor-disp {
        display: flex !important;
    }
}
