productslider.css

.product-slider {
    padding: 0 10px; 
}

.slider-item {
    padding: 0 10px; 
}

.slider-item > * {
    height: 100%;
    width: 100%;
}

.slick-slide {
    display: flex;
    justify-content: center; 
}

.slick-dots li button:before {
    color: #000; 
}
