



.floating-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.575);
    z-index: -1;
    position: absolute;
    top: 10px; /* Make sure to add 'px' for the unit */
    left: 10px; /* Make sure to add 'px' for the unit */
    overflow: none;
}


#one
{
    animation: floating_1 15s  infinite;
}

#two
{
    animation: floating_2 15s ;
}

@keyframes floating_1 {
    from
    {
        top:650px;
        left:10px;
    }
    to
    {
        left: 200px;
        top: -100px;
    }

    
}


@keyframes floating_2 {
    from
    {
        top: 1000px;
        left:1000px;
    }
    to
    {
        left: 20px;
        top: -100px;
    }

    
}



/* This sets the default style for all screen sizes */
.custom-lg-class {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

/* This media query overrides the default style for 'lg' screens and above */
@media (min-width: 992px) { /* Bootstrap 5 'lg' breakpoint */
    .custom-lg-class {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        /* Reset other corners to 0 if needed */
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}





/* This sets the default style for all screen sizes */
.custom2-lg-class {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* This media query overrides the default style for 'lg' screens and above */
@media (min-width: 992px) { /* Bootstrap 5 'lg' breakpoint */
    .custom2-lg-class {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        /* Reset other corners to 0 if needed */
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}



