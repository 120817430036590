.hero-image 
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/header.jpg");
  
    height: 560px;
    width: 100%;
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .side-image-1
  {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/1.png");
    
      height: 600px;
      width: 100%;
    
      background-position: 0% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }

  .side-image-2
  {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/2.jpg");
    
      height: 600px;
      width: 100%;
    
      background-position: 0% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  } 
  
.hero-text 
  {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 10%);
    color: rgba(255, 255, 255, 0.799);
  }

  .hero-text2
  {
    
  font-family: Revans-Bold, var(--font-heading-family) !important;
  font-size: 48px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  line-height: 0.9 !important;
  letter-spacing: 0em !important;

  }

  .hero-text3
  {
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: 64px;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 0.9 !important;
    letter-spacing: 0em !important;
  color: white;

  }

  .hero-text4
  {
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: large;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: 0em !important;
  color: white;

  }

  .hero-text5
  {
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: 64px;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: 0em !important;
  color: white;

  }


  .hero-text6
  {
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: 64px;
    font-weight: 400 !important;
    font-style: normal !important;
    line-height: 1.0 !important;
    letter-spacing: 0em !important;
  color: white;

  }


.upper-text-holder
{
    display: flex;
    justify-content: center;
    width: 50%;

}


    @media(max-width: 830px)
    {
        .upper-text-holder
        {
            width: 75%;
        }

    }

    @media(max-width: 570px)
    {
        .upper-text-holder
        {
            width: 90%;
        }
        .hero-text3
        {
            font-size: 36px;
        }
        .hero-text5
        {
            font-size: 36px;

        }

        .hero-text6
        {
            font-size: 36px;

        }
    }


  @media (max-width: 768px) {
    .hero-image
    {
        height: 350px;
    }

    .hero-text2
    {
        font-size: 36px !important;
    }
  }

.textholder-2
{
    /* height: 350px; */

    background-color: #353535;


}

.big-picture
{
    width: 80%;
    border-radius: 10px;
    animation: slide-right 0.9s ease-out;
}

.big-picture-two
{
    width: 80%;
    border-radius: 10px;
}


.fade-in
{
    opacity: 0.4;
    animation: fadeIn 1s;
}

.text-box
{
   
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: 100%;

    border-radius: 10px;
    background-color: whitesmoke;
    animation: slide-top-down 2s;
    box-shadow: 0px 0px 15px 1px black;
}

.breaker
{
    width: 100%;
    height: 0px;
    background-color: black;
    box-shadow: 0px 0px 20px 20px;
 

}


@keyframes slide-right {
    from
    {
        opacity: 0;
        transform: translateX(-400px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
}


@keyframes slide-top-down {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;    
    }
}

@keyframes slide-left {
    from{
        transform: translateX(-400px);
    }
    to{
        transform: translateX(0px);
    }

    

}


@keyframes fadeIn {
    from { opacity: 0.4; }
    to { opacity: 1; }
  }