
.card-size
{
    width: 250px; /* Full width of the parent column */
    max-width: 200px; /* Maximum width */
    height: auto; /* Height adjusts to content */
    max-height: 300px; /* Maximum height */
    background-color: red;


}


.product-card-anim
{   
    transform: rotateX(0deg);
    animation: fall-down 0.7s;
}

.product-card-trans
{
    animation: extrans 0.5;

}

@keyframes extrans {

    from{
        opacity: 0.5;
    }
    to{
        opacity: 1.0;
    }
    
}

.text-roll
{
    animation: fall-down 0.7s;
}

.hidden-product
{
    opacity: 0.5;
}


.overlay
{
    display: flex;
    justify-content: center;
    align-items: end;
    color: rgba(250, 235, 215, 0);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    animation: fall-down 0.7s;
}

.overlay:hover
{
    color: rgba(255, 255, 255, 0);
    /* background-color: rgba(255, 255, 255, 0.585); */
    background-color: rgba(255, 255, 255, 0.435);
}

.overlaytwo:hover .to-product
{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.741);
    color: black;
    width: 100%;
    height: 15%;
    border-radius: 0px 0px 10px 10px;
    animation: slide-up 0.5s;
}


@media (max-width: 810px)
{
    .overlay:hover
    {
        display: none;
        background-color: rgba(19, 84, 141, 0.836);
    }



}



@keyframes slide-up {
    from{
        height: 0%;
        color: rgba(0, 0, 0, 0);
    }
    to{
        color: black;
        height: 15%;
    }

    
}



@keyframes fall-down {
    from{
        transform: rotateX(90deg);
    }
    to{
        transform: rotateX(0deg);
    }
}

.fadein
{
    animation: kappa 3s linear;


}



@keyframes kappa {
    from{
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }


}

