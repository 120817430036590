


  
  .incDecPress:active {
    animation: pressing 0.2s ease;
    border-radius: 50%;
   
}

  @keyframes pressing {
    0% {
      transform: scale(1) translateY(0); /* Start at full size, no vertical translation */
    }
    50% {
      transform: scale(0.95) translateY(2px); /* Shrink and move down slightly */
    }
    100% {
      transform: scale(1) translateY(0); /* Return to full size and original position */
    }
  }

