


.header-slider-image-1 
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/header.jpg");
  
    height: 100%;
    min-width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-button-image-1
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    /* background-color: green; */
    height: 25%;
    width: 25%;
    left: 15%;
    top: 20%;

}

.header-button-image-2
{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    /* background-color: green; */
    height: 25%;
    width: 25%;
    left: 15%;
    top: 20%;

}

/* @media (max-width: 1198px)
{
    .header-button-image-1
    {
        top: 10%;
        width: 45%;
    }

} */



.slider-text-header
{
    
font-family: Revans-Bold, var(--font-heading-family);
font-size: 42px ;
font-weight: 700 ;
font-style: normal ;
color: white;
line-height: 0.9 ;
letter-spacing: 0em ;
}

.slider-text-body
{

    font-family: Revans-Bold, var(--font-heading-family) !important;
font-size: 26px !important;
font-weight: 300 !important;
font-style: normal !important;
color: white;
line-height: 0.9 !important;
letter-spacing: 0em !important;

}

.image-holder-front 
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/frontpageimg.png");
  
    height: 100%;
    min-width: 100%;


    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.image-holder-front-text
{
   
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: 36px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    color: white;
    line-height: 0.9 !important;
    letter-spacing: 0em !important;

}

.image-holder-front-text2
{
   
    font-family: Revans-Bold, var(--font-heading-family) !important;
    font-size: 26px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    color: white;
    line-height: 0.9 !important;
    letter-spacing: 0em !important;

}

.image-holder-front-text-container
{
    position: absolute;
    top: 70%;
    left: 32%;
}

@media (max-width: 468px)
{
    .image-holder-front-text-container
    {
        left: 5%;
        top: 60%;


    }



}

@media (max-width: 1198px)
{
    .header-button-image-1
    {
        top: 10%;
        width: 55%;
    }
    .header-button-image-2
    {
        top: 55%;
        left: 30%;
        width: 55%;
    }


}

@media (max-width: 476px)
{
    .header-button-image-1
    {
        top: 7%;
        width: 75%;
    }

    .header-button-image-2  
    {
        left: 20%;
        width: 75%;
    }


}


@media (max-width: 780px)
{
   

    .slider-text-header
    {
        font-size: 26px !important;
    }

    .slider-text-body
    {
        font-size: 14px !important;
    }

   
}


.header-slider-image-2
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/header2.jpg");
    height: 100%;
    min-width: 100%;
  
    background-position: 50% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;


}  

.header-slider-image-3
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../pictures/header3.jpg");
    height: 100%;
    min-width: 100%;
  
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;


}  



  .carousel-height
  {
    height: 40vw;


  }



  @media (max-width: 768px) {
    .carousel-height
    {
        height: 350px;
    }

    /* .hero-text2
    {
        font-size: 36px !important;
    } */
  }


 