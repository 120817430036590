


.tiltle-fade{



}


.fade-in-products {
    animation: fading-in-prod 0.5s;   
}

@keyframes fading-in-prod {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }

}